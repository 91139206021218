import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { ApiLoader } from '@utils/helpers/apiLoader';

import PoweredBy from '@assets/images/powered-by.svg';
import PoweredByNiceCredit from '@assets/images/powered-by-nice-credit.svg';

interface IProps {}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    marginBottom: 50,
    position: 'relative'
  },
  children: {
    height: 'calc(100% - 55px)',
    display: 'flex'
  },
  unsupportedCookies: {
    height: 40,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    background: 'orange'
  },
  poweredBy: {
    width: '100%',
    padding: '11.5px 0',
    textAlign: 'center',
    background: 'white'
  }
}));

const PublicLayout: React.FC<IProps> = ({ children }) => {
  const classes = useStyles();
  let hasPermissionToAccessLocalStorage;

  var logo =
    process.env.REACT_APP_ENV !== 'production'
      ? PoweredByNiceCredit
      : PoweredBy;

  try {
    localStorage.getItem('accessToken');
    hasPermissionToAccessLocalStorage = true;
  } catch (error) {
    hasPermissionToAccessLocalStorage = false;
  }

  return (
    <>
      {!hasPermissionToAccessLocalStorage && (
        <div className={classes.unsupportedCookies}>
          {' '}
          For the better experience please allow third-party cookies and site
          data in your browser settings or don't use incognito mode.{' '}
        </div>
      )}
      <div>
        <ApiLoader />
      </div>
      <div className={classes.children}>{children}</div>
      <div className={classes.poweredBy}>
        <img src={logo} alt="" />
      </div>
    </>
  );
};

export default PublicLayout;
